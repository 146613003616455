body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --red-regular: #ed0000;
    --blue-regular: #598ab3;
    --blue-medium: #b8c3cc;
    --blue-light: #e1e6ea;
    --blue-superlight: #f9fafb;
    --green-regular: #62c370;
    --orange-regular: #f79000;
    --black: #3f3f3f;
    --white: #ffffff;
}

@font-face {
    font-family: Montserrat;
    src: local(Montserrat-Medium),
    url(fonts/Montserrat-Medium.woff2) format("woff2"),
    url(fonts/Montserrat-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Montserrat;
    src: local(Montserrat-Bold),
    url(fonts/Montserrat-Bold.woff2) format("woff2"),
    url(fonts/Montserrat-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}
